<template>
  <div>
    <div class="modal_header">
      <el-row >
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t('message.new_room') }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="save()" :loading="loadingButton" :disabled="loadingButton ? true : false" type="success">{{ $t('message.new_room') }}</el-button>
              <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form ref="form" :model="form" :rules="rules" class="aticler_m" >
      <el-row :gutter="24">

        <el-col :sm="12" class="mt14">
          <div class="grid-content">
            <el-form-item :label="columns.name.title" prop="name">
              <el-input v-model="form.name" :placeholder="columns.name.title"></el-input>
            </el-form-item>
          </div>
        </el-col>

        <el-col :sm="12" class="mt14">
          <div class="grid-content">
            <el-form-item :label="columns.room_number.title" prop="room_number">
              <el-input v-model="form.room_number" :placeholder="columns.room_number.title"></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="24">
          <el-tree
              class="filter-tree"
              :data="data"
              node-key="id"
              :filter-node-method="filterNode"
              ref="items"
              @node-click="handleNodeClick"
              :expand-on-click-node="true"
              show-checkbox
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="aaa my-tree-khan">
                <div>{{ data.name }}</div>
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>

import { mapGetters, mapActions} from "vuex";
import form from '@/utils/mixins/form';
export default {
  mixins:[form],
  data() {
    return {
      data: [],
    }
  },
  computed: {
    ...mapGetters({
      roles: 'rooms/list',
      rules: 'rooms/rules',
      model: 'rooms/model',
      columns: 'rooms/columns',
      services_list: "services/relation_list",
    })
  },
  async mounted() {
    await this.servicesList({ relation: true }).then((res) => {
      this.data = res.data.services;
    });
  },
  async created() {},
  methods: {
    ...mapActions({
      roomSave: 'rooms/store',
      servicesList: "services/relationList",
    }),
    save(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          let formData = new FormData();
          for(let key in this.form) {
            if (this.form[key] != null) {
              formData.append(key, this.form[key]);
            } else {
              formData.append(key, '');
            }
          }
          formData.append(
              "services",
              JSON.stringify(this.$refs.items.getCheckedNodes())
          );
          this.roomSave(formData)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res.message);
              this.parent().listChanged()
              this.resetForm('form');
            }).catch(err => {
              this.loadingButton = false;
              this.$notify({
                title: 'Ошибка',
                type: "error",
                offset: 130,
                message: err.error.message
              });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit('c-close',{drawer: 'drawerCreate'});
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
  },
  components:{
  }
}
</script> 